import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const StockLogService = () => {}

StockLogService.GetStockLog = async(queryString)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/log?${queryString}`, {})
}

StockLogService.GetStockLogBySerial = async(serial)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/log/${serial}`, {})
}

StockLogService.InsertStockLog = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/log`, payload)
}

 export default StockLogService