<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Stock Movement Detail</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button
                @click="$router.push('/stock/movement')"
                variant="outline-primary"
                class="ml-1 shadow"
                >
                <feather-icon icon="ArrowLeftIcon" />
                ย้อนกลับ
              </b-button>
            </b-form>
          </b-col>
        </b-row>

        <b-row >
          <b-col>
            <b-alert variant="secondary" show>
              <div class="alert-body">
                <b class="text-primary">Serial : {{ qs.serial }}</b> > {{ qs.t }} >  {{ qs.b }} > {{ qs.m }}
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
              :tbody-tr-class="rowClass"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>
              <template #cell(assignTo)="row">
                {{ row.item.assignToName || "-" }}
              </template>  

              <template #cell(ticketNo)="row">
                <center><b>{{ row.item.ticketNo || "-" }}</b></center>
              </template>   
              <template #cell(actionDetail)="row">
                <b>{{ row.item.actionDetail }}</b>
              </template>                            

              <template #cell(createAt)="row">
                <center>{{ convertDate(row.item.createAt, "orginal") }}</center>
              </template>

              <template #cell(action)="row">
                <center>                  
                  <b-button variant="primary" size="sm" pill v-if="row.item.actionDetail.includes('setup')" @click="setOnhand(row.item)">
                    Onhand
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BSpinner,
  BForm,
  BAlert
} from "bootstrap-vue";

import Field from "./field/detail";

//SERVICES
import StockLogService from "@/services/stock/log";
import OnhandService from "@/services/stock/onhand";
import moment from "moment";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BSpinner,
    BForm,
    BAlert
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 3000, 4000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      isLoading: false,
      userLogin:{}
    };
  },

  computed:{
    qs(){
      return this.$route.query
    },
    stockPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("STK005")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission       
    },
  },

  created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
    this.getStockLog();
  },
  mounted() {
    
  },
  methods: {
    async getStockLog() {
      this.isLoading = true;
      const qSerial = this.$route.query.serial;  
      const result = await StockLogService.GetStockLogBySerial(qSerial);
      this.items = result.data;
      this.totalRows = this.items.length;
      this.isLoading = false;
    },
    async setOnhand(item){
      const obj = {
        ...item,
        createBy:this.userLogin.userId,
        serial:this.qs.serial
      }
      const result = await OnhandService.InsertOnhandStock(obj)
      await this.getStockLog()
    },
    convertDate(date, type = "") {
      if (type == "date") {
        return moment(date).format("yyyy-MM-DD");
      }

      if(type=="orginal"){
        return moment(date).subtract(7, 'h').format("yyyy-MM-DD HH:mm:ss");
      }
      return moment(date).format("yyyy-MM-DD HH:mm:ss");
    },
    rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.actionDetail === 'setup') return 'table-setup'
        if (item.actionDetail === 'Set moveback') return 'table-moveback'
    }, 
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
.table-setup{
  background-color: rgba(83, 167, 206, 0.329) !important;
}

.table-moveback{
  background-color: rgba(199, 214, 112, 0.329) !important;
}
</style>
