export default [
  {
    key: "no",
    label: "#",
    thStyle:{textAlign: "center",color:"white",width: "4% !important"}
  },
  {
    key: "assignTo",
    label: "Engineer",
    thStyle:{textAlign: "center",color:"white",width: "12% !important"}
  },
  
  { key: "ticketNo", label: "Job No", sortable: true , thStyle:{textAlign: "center",color:"white",width: "10% !important"}},
  { key: "actionDetail", label: "Action", sortable: true ,thStyle:{textAlign: "center",color:"white",width: "20% !important",}}, 
  { key: "createAt", label: "Datetime", sortable: true ,thStyle:{textAlign: "center",color:"white",width: "10% !important",}}, 
  
  
  {
    key: "branchName",
    label: "From",
    sortable: false,
    thStyle: {  textAlign: "center" ,color:"white",width: "15% !important"},
  },
  { key: "createBy", label: "Action by", sortable: true , thStyle:{color:"white",width: "10% !important"}},
  {
    key: "action",
    label: "Send to",
    sortable: false,
    thStyle: { width: "7% !important", textAlign: "center", color:"white" },
  },
];
